import { extendTheme } from "@chakra-ui/react";

const theme = {
  styles: {
    global: {
      body: {
        bg: "purple.50",
        color: "purple.800",
        minHeight: "100vh",
        backgroundAttachment: "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
    },
  },
};

export default extendTheme(theme);
